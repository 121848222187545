import React from 'react'

function Header({ isHeaderTransparent }) {
    return (
        <div className={`${isHeaderTransparent ? 'transparent' : ''} header-section header-sticky res-header section`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/*Logo*/}
                        <div className="float-left">
                            <a href="#" className="header-logo"><img height={40} src="img/logo/logo.png" alt /></a>
                        </div>
                        {/*Menu*/}
                        <div className="float-right">
                            <nav className="res-menu main-menu one-page-menu text-black">
                                <ul>
                                    <li className="active"><a href="#res-home">Home</a></li>
                                    <li><a href="#res-about">About</a></li>
                                    <li><a href="#res-menu">Categories</a></li>
                                    <li><a href="#res-gallery">Gallery</a></li>
                                    <li><a href="#res-reservation">Feedback</a></li>
                                    <li><a href="#res-contact">Contact</a></li>
                                </ul>
                            </nav>
                            {/* <div className="header-search">
                                <button className="search-toggle res-search text-black"><i className="ion-android-search" /></button>
                                <div className="header-search-form">
                                    <form action="#">
                                        <input type="text" placeholder="Search" />
                                        <button><i className="ion-android-search" /></button>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                        {/*Mobile Menu*/}
                        <div className="res-menu mobile-menu one-page text-black" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header