import React from 'react'

const TestimonialCard = ({ data }) => {
    return (
        <div className="res-testimonial-slide-item text-center">
            <img src="img/restaurant/icon/quote.png" alt />
            <p>{data.comments}</p>
            <h5>{data.name}</h5>
            <span>{data.designation}</span>
        </div>
    )
}

function Testimoni() {


    const data = [
        { id: 1, name: "Jane Doe", comments: "Addware has revolutionized my DIY projects. Their wide range of tools and hardware products has made every task a breeze. From plumbing to electrical work, Addware is my go-to destination. Quality and convenience, all in one place!", designation: "CEO at Design" },
        { id: 2, name: "John Smith", comments: "Addware has redefined my home improvement journey. Their exceptional customer support guided me through my choices, and their prompt delivery amazed me. As an enthusiast, I find everything I need, plus expert advice. Addware truly elevates hardware shopping.", designation: "Valuable Customer" },
        { id: 3, name: "Emily Brown", comments: "Addware is my partner in crafting my dream home. From stylish furnishings to cutting-edge electronics, they have it all. Their user-friendly interface and efficient delivery ensure that I get what I need, when I need it. Addware makes my home truly unique.", designation: "Actor" },
    ]

    return (
        <div><div id="res-testimonial" className="res-testimonial-section section pb-150">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="res-testimonial-wrapper">
                            <div className="res-testimonial-slider">
                                {data.map((item) => {
                                    return (
                                        <TestimonialCard data={item} />
                                    )
                                })}

                                {/* <div className="res-testimonial-slide-item text-center">
                                        <img src="img/restaurant/icon/quote.png" alt />
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam commodo et leo ac conv Aenean quis
                                            consequat diam. In nec ornare metus, eu cursus nibh. Aenean sceleri maledignissim. Sed a sem
                                            sollicitudin elit faucibus dignissim Pellentesque.</p>
                                        <h5>Sathy Bhuiyan</h5>
                                        <span>CEO at Design</span>
                                    </div>
                                    <div className="res-testimonial-slide-item text-center">
                                        <img src="img/restaurant/icon/quote.png" alt />
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam commodo et leo ac conv Aenean quis
                                            consequat diam. In nec ornare metus, eu cursus nibh. Aenean sceleri maledignissim. Sed a sem
                                            sollicitudin elit faucibus dignissim Pellentesque.</p>
                                        <h5>N Sharif</h5>
                                        <span>CEO of Design</span>
                                    </div>
                                    <div className="res-testimonial-slide-item text-center">
                                        <img src="img/restaurant/icon/quote.png" alt />
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam commodo et leo ac conv Aenean quis
                                            consequat diam. In nec ornare metus, eu cursus nibh. Aenean sceleri maledignissim. Sed a sem
                                            sollicitudin elit faucibus dignissim Pellentesque.</p>
                                        <h5>Tasnim Akter</h5>
                                        <span>CEO of Design</span>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div id="res-contact" className="res-footer-section section pt-150 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 mb-40">
                            <div className="res-footer-widget">
                                <img className="res-footer-logo" src="img/logo/logo-2.png" alt />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-40">
                            <div className="res-footer-widget">
                                <h4 className="title">Contact Us</h4>
                                <ul>
                                    <li><span>Address :</span> ADDWARE INDUSTRIES PVT.LTD,Room No XII/375, Calicut Road, Angadipuram,697321</li>
                                    <li><span>Email :</span> <a href="#">addwareindia@gmail.com</a> <a href="#">addwareindia@gmail.com</a></li>
                                    <li><span>Phone :</span> <a href="#">(+91756 088 0111)</a> <a href="#">(+91756 088 0222)</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-40">
                            <div className="res-footer-widget">
                                <h4 className="title">Subscribe</h4>
                                <p>Nulla tempor, nisl sed bibendum pretium, diam nisi mattisss lectus, eu varius lorem mauris posuere dui.
                                </p>
                                <div id="mc_embed_signup" className="res-footer-newsletter">
                                    <form action="https://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&id=05d85f18ef" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                        <div id="mc_embed_signup_scroll" className="mc-form">
                                            <div className="mc-field-group">
                                                <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Your Email Address" />
                                            </div>
                                            <div id="mce-responses" className="clear">
                                                <div className="response" id="mce-error-response" style={{ display: 'none' }} />
                                                <div className="response" id="mce-success-response" style={{ display: 'none' }} />
                                            </div>
                                            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef" tabIndex={-1} defaultValue /></div>
                                            <div className="clear"><button type="submit" name="subscribe" id="mc-embedded-subscribe" className="submit"><i className="fa fa-paper-plane" /></button></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>
    )
}

export default Testimoni