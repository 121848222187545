import React from 'react'

function Gallery() {

    const data = [
        { id: 1, img: "img/restaurant/gallery/a.jpg", alt: "Lobster Pot Pies recipe" },
        { id: 2, img: "img/restaurant/gallery/b.png", alt: "Lobster Pot Pies recipe" },
        { id: 3, img: "img/restaurant/gallery/c.jpg", alt: "Lobster Pot Pies recipe" },
        { id: 4, img: "img/restaurant/gallery/d.jpg", alt: "Lobster Pot Pies recipe" },
        { id: 5, img: "img/restaurant/gallery/a.jpg", alt: "Lobster Pot Pies recipe" },
        { id: 6, img: "img/restaurant/gallery/b.jpg", alt: "Lobster Pot Pies recipe" },

    ]
    return (
        <div><div id="res-gallery" className="res-gallery-section section fix">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="isotop-grid row">
                            <div className="isotop-item res-gallery-item col-lg-3 col-md-4 col-12 mb-30">
                                <a href="img/restaurant/gallery/1.png" className="gallery-popup"><img src="img/restaurant/gallery/1.png" alt /><span> Sanitary</span></a>
                            </div>
                            <div className="isotop-item res-gallery-item col-lg-6 col-md-8 col-12 mb-30">
                                <a href="img/restaurant/gallery/2.png" className="gallery-popup"><img src="img/restaurant/gallery/2.png" alt /><span> Stationery</span></a>
                            </div>
                            <div className="isotop-item res-gallery-item col-lg-3 col-md-4 col-12 mb-30">
                                <a href="img/restaurant/gallery/3.png" className="gallery-popup"><img src="img/restaurant/gallery/3.png" alt /><span> Storage Items</span></a>
                            </div>
                            <div className="isotop-item res-gallery-item col-lg-6 col-md-8 col-12 mb-30">
                                <a href="img/restaurant/gallery/4.png" className="gallery-popup"><img src="img/restaurant/gallery/4.png" alt /><span> Furnitures</span></a>
                            </div>
                            <div className="isotop-item res-gallery-item col-lg-3 col-md-4 col-12 mb-30">
                                <a href="img/restaurant/gallery/5.png" className="gallery-popup"><img src="img/restaurant/gallery/5.png" alt /><span> Paints</span></a>
                            </div>
                            <div className="isotop-item res-gallery-item col-lg-3 col-md-4 col-12 mb-30">
                                <a href="img/restaurant/gallery/6.png" className="gallery-popup"><img src="img/restaurant/gallery/6.png" alt /><span> Electronics </span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default Gallery