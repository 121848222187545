import React from 'react'

function Reservation() {
    return (
        <div><div id="res-reservation" className="res-opening-reservation-section section pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="res-opening-reservation-wrapper">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 col-12 mb-30">
                                <div className="res-opening-wrapper">
                                    <div className="res-opening-time-list">
                                        <div className="title text-center">
                                            <h3>Opening Time</h3>
                                            <img src="img/restaurant/icon/section-title-icon-sm-red.png" alt />
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="days">Monday- Friday</span>
                                                <span className="times">10AM - 6PM</span>
                                            </li>
                                            <li>
                                                <span className="days">Saturday</span>
                                                <span className="times">10PM - 6PM</span>
                                            </li>
                                            <li>
                                                <span className="days">Sunday</span>
                                                <span className="times">Close</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="res-opening-time-online text-center">
                                        <h5>All Time Available In Online</h5>
                                        <a href="#"><i className="icon-facebook" /></a>
                                        <a href="#"><i className="icon-twitter" /></a>
                                        <a href="#"><i className="icon-googleplus" /></a>
                                        <a href="#"><i className="icon-dribbble" /></a>
                                        <a href="#"><i className="icon-linkedin" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6 col-12 mb-30">
                                <div className="res-reservation-wrapper">
                                    <h3>Contact us Form</h3>
                                    <form action="#" id="reservation-form">
                                        <div className="row">
                                            <div className="col-12"><input type="text" placeholder="Full Name" /></div>
                                            <div className="col-12"><input type="email" placeholder="Email Address" /></div>
                                            <div className="col-12"><input type="text" placeholder="Mobile Number" /></div>
                                            <div className="col-12"><input className="date-time" type="text" placeholder="Select Date And Time" /></div>
                                            <div className="col-12"><input type="submit" defaultValue="Submit" /></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default Reservation