import React, { useEffect } from 'react'

function Footer() {

    const year = new Date().getFullYear()

    return (
        <div><div className="res-footer-bottom section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="res-copyright text-center">
                            <p>Copyright©{year}  <a href="#">Addware Industries PVT.LDT  </a>. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default Footer