import logo from './logo.svg';
import './App.css';
import { render } from '@testing-library/react';
import Header from './Components/Header';
import Herosection from './Components/Herosection';
import About from './Components/About';
import Dishes from './Components/Dishes';
import Menu from './Components/Menu';
import Gallery from './Components/Gallery';
import Reservation from './Components/Reservation';
import Testimoni from './Components/Testimoni';
import Footer from './Components/Footer';

import React, { useState, useEffect } from 'react';

function App() {
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsHeaderTransparent(true);
    } else {
      setIsHeaderTransparent(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>

      {/*Creative 1 Header Section*/}
      <Header isHeaderTransparent={isHeaderTransparent} />

      {/*Restaurant Hero Section*/}
      <Herosection />


      {/*Restaurant About Section*/}
      <About />


      {/*Restaurant Dishes Section*/}
      <Dishes />


      {/*Restaurant Dishes Menu Section*/}
      <Menu />


      {/*Restaurant Gallery Section*/}
      <Gallery />


      {/*Restaurant Opening & Reservation Section*/}
      <Reservation />

      {/*Restaurant Testimonial Section*/}
      <Testimoni />

      {/*end footer section*/}
      <Footer />



    </>
  );
}


export default App;
