import React from 'react'

function Dishes() {
    return (
        <div><div id="res-dishes" className="res-dishes-section section pt-150 pb-150">
            <div className="container">
                <div className="row">
                    <div className="res-section-title text-center col-12 mb-80">

                        <h1>Why <span>choose </span> us</h1>
                        <br />
                        <p>
                            <h5 >Quality Assurance</h5>We believe in offering only the best. Our products undergo rigorous quality checks to ensure they meet the highest industry standards.<br /><br />
                            <h5>Expertise</h5> Backed by a team of hardware experts, we're committed to providing you with accurate information and helpful advice to guide your purchase decisions.<br /><br />
                            <h5>Wide Selection</h5> From essential components to the latest gadgets, our diverse range of products caters to various interests and requirements.<br /><br />
                            <h5>Customer Satisfaction</h5> Your satisfaction is our priority. We're dedicated to delivering prompt customer support and addressing any queries you may have.<br /><br />
                            <h5>Innovation</h5> We keep up with the ever-evolving hardware landscape, bringing you innovative and cutting-edge products that enhance your tech experiences.<br /><br />

                        </p>
                        {/* <img src="img/restaurant/icon/section-title-icon.png" alt /> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12 mb-30">
                        <div className="res-dishes-item">
                            <img src="img/restaurant/dishes/1a.png" alt />
                            <div className="content">
                                <h5 className="title">Home Appliances</h5>
                                <h3 className="price">Discover, Shop, and Save </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-30">
                        <div className="res-dishes-item">
                            <img src="img/restaurant/dishes/2a.png" alt />
                            <div className="content">
                                <h5 className="title">Paints&Colours</h5>
                                <h3 className="price">Explore Beyond, Shop Today</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-30">
                        <div className="res-dishes-item">
                            <img src="img/restaurant/dishes/3a.png" alt />
                            <div className="content">
                                <h5 className="title">Hardwares</h5>
                                <h3 className="price">Grab Tools</h3>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 text-center mt-50">
                        <a href="#res-menu" data-scroll className="btn btn-res btn-icon-right btn-res-hover-border">View all menu<i className="fa fa-long-arrow-right right" /></a>
                    </div> */}
                </div>
            </div>
        </div></div>
    )
}

export default Dishes