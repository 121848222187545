import React from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import lf_about from '../Assets/lottiefiles/aboutus.json'

function About() {
    return (
        <div> <div id="res-about" className="res-about-section section">
            <div className="container-fluid">
                <div className="row">
                    {/*About Image*/}
                    <div className="res-about-image col-lg-6 col-12"  >
                        <Player
                            autoplay
                            loop
                            src={lf_about}
                            style={{ height: '550px', width: '575px' }}
                        >
                            {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                        </Player>
                    </div>






                    {/*About Content*/}
                    <div className="res-about-content col-lg-6 offset-lg-6 col-12">
                        <h4>Welcome to Addware – Your Ultimate Hardware Destination!.</h4>
                        <p>At Addware, we're passionate about all things hardware. Whether you're a tech enthusiast, a DIYer, or a professional,
                            we're here to provide you with top-quality hardware solutions that cater to your needs.
                            Our mission is to make your hardware shopping experience seamless, informative, and enjoyable.
                        </p>
                        <p>

                            Join us in exploring the world of hardware. Shop with confidence and elevate your tech journey with Addware.
                            Feel free to reach out to us at addware@gmail.com for any inquiries or assistance.
                            Thank you for choosing Addware!
                        </p>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default About