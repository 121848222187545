import React from 'react'

function Herosection() {
    return (
        <div><div id="res-home" className="res-hero-section section">
            {/*Creative 1 Hero Slider*/}
            <div className="res-hero-slider">
                {/*Hero Slide Item*/}
                <div className="res-hero-slide-item" style={{ backgroundImage: 'url("img/restaurant/hero/bg2.webp")' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/*Hero Slide Content*/}
                                <div className="res-hero-slide-content">
                                    <h1>Do <span>Your</span> own!</h1>
                                    <p>A Grand new era of shopping online with hardwares. Come grab the apps for best user experience </p>
                                    <a href="https://play.google.com/store/apps/details?id=com.addwareindia" target='blank' data-scroll className="btn btn-res btn-res-hover-border pl-2 mx-1">Android</a>
                                    <a href="https://apps.apple.com/us/app/addware/id1660668400" target='blank' data-scroll className="btn btn-res btn-res-hover-border">IOS</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default Herosection