import React from 'react'


const MenuItems = ({ data }) => (
    <div className="isotop-item res-food-menu-item dinner coffee col-lg-6 col-12">
        <div className="image float-left"><img height={60} width={60} src={data.image} /></div>
        <div className="content fix">
            <h5>{data.name}</h5>
            <p>{data.description}.</p>
            {/* <h4>$20</h4> */}
        </div>
    </div>
)

function Menu() {


    const menuItems = [

        { "id": 1, "name": "Archive", "description": "Storing Memories", "image": "/img/categories/archive.png" },
        { "id": 2, "name": "Christmas Lights", "description": "Festive Illumination", "image": "/img/categories/christmas-lights.png" },
        { "id": 3, "name": "Cosmetics", "description": "Beauty and Elegance", "image": "/img/categories/cosmetics.png" },
        { "id": 4, "name": "Crayons", "description": "Vibrant Creativity", "image": "/img/categories/crayons.png" },
        { "id": 5, "name": "Electrical Energy", "description": "Powering the Future", "image": "/img/categories/electrical-energy.png" },
        { "id": 6, "name": "Electronics", "description": "Cutting-Edge Innovations", "image": "/img/categories/electronics.png" },
        { "id": 7, "name": "Gardening Tools", "description": "Nurturing Green Spaces", "image": "/img/categories/gardening-tools.png" },
        { "id": 8, "name": "Hammer Drill", "description": "Building with Precision", "image": "/img/categories/hammer-drill.png" },
        { "id": 9, "name": "Home", "description": "Your Comfort Zone", "image": "/img/categories/Home.png" },
        { "id": 10, "name": "House", "description": "Brick by Brick", "image": "/img/categories/house.png" },
        { "id": 11, "name": "Houseware", "description": "Essentials of Home", "image": "/img/categories/houseware.png" },
        { "id": 12, "name": "Mop", "description": "Cleaning Made Easy", "image": "/img/categories/mop.png" },
        { "id": 13, "name": "Paint", "description": "Adding Color to Life", "image": "/img/categories/paint.png" },
        { "id": 14, "name": "Paving", "description": "Pathways of Beauty", "image": "/img/categories/paving.png" },
        { "id": 15, "name": "Plumbing", "description": "Flowing Solutions", "image": "/img/categories/plumbing.png" },
        { "id": 16, "name": "Roof", "description": "Shelter from Above", "image": "/img/categories/roof.png" },
        { "id": 17, "name": "Rope", "description": "Tied Together", "image": "/img/categories/rope.png" },
        { "id": 18, "name": "Saw Blade", "description": "Precision Cutting", "image": "/img/categories/saw-blade.png" },
        { "id": 19, "name": "Sofa", "description": "Comfort Redefined", "image": "/img/categories/sofa.png" },
        { "id": 20, "name": "Stationery", "description": "Creative Essentials", "image": "/img/categories/stationery.png" },
        { "id": 21, "name": "Stationery 2", "description": "More Creative Essentials", "image": "/img/categories/stationery_2.png" },
        { "id": 22, "name": "Tableware", "description": "Setting the Table", "image": "/img/categories/tableware.png" },
        { "id": 23, "name": "Tape Measure", "description": "Measured Precision", "image": "/img/categories/tape-measure.png" },
        { "id": 24, "name": "Tape", "description": "Sticking Together", "image": "/img/categories/tape.png" },
        { "id": 25, "name": "Toilet", "description": "A Private Oasis", "image": "/img/categories/toilet.png" },
        { "id": 26, "name": "Tools", "description": "Craftsmanship Companion", "image": "/img/categories/tools.png" },
        { "id": 27, "name": "Tools 1", "description": "Craftsmanship Essential", "image": "/img/categories/tools_1.png" },
        { "id": 28, "name": "Trolley", "description": "Rolling Convenience", "image": "/img/categories/trolley.png" },
        { "id": 29, "name": "Umbrella", "description": "Rainy Day Friend", "image": "/img/categories/umbrella.png" },
        { "id": 30, "name": "Under Construction", "description": "Work in Progress", "image": "/img/categories/under-construction.png" },
        { "id": 31, "name": "Water", "description": "Life's Essential", "image": "/img/categories/water.png" }
    ]

    return (
        <div>
            <div id="res-menu" className="res-dishes-menu-section section pt-150 pb-150" style={{ backgroundImage: 'url(img/restaurant/bg/dishes-menu-section.jpg)' }}>
                <div className="container">
                    <div className="row">
                        <div className="res-section-title title-white text-center col-12 mb-80">
                            <h1>Our <span>Top</span> Categories</h1>
                            <p>Welcome to Addware – your ultimate destination for all things hardware! With an extensive array of product categories, we cater to every facet of your hardware needs. Our curated selection spans across an impressive range, including home appliances, furniture, tools, hand tools, paints, stationary, cookware, sanitary ware, taps and bathroom fittings, cutting tools, agricultural tools, building ware tools, plumbing essentials, electrical components, and much more. We take pride in providing top-notch solutions that meet the demands of professionals, DIY enthusiasts, and homeowners alike. Explore our categories and unlock a world of possibilities to elevate your projects and spaces. Your hardware journey starts here!.</p>
                            <img src="img/restaurant/icon/section-title-icon-sm-red.png" height={100} width={100} alt />
                        </div>


                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="res-food-menu-wrapper">
                                <div className="res-food-menu-filter isotop-filter text-center">
                                    <label className="xactive" data-target="#food-menu-grid" data-filter="*">Top Categories :</label>
                                    <label data-target="#food-menu-grid" data-filter=".breakfast">HOME WARE</label>
                                    <label data-target="#food-menu-grid" data-filter=".lunch">HARD WARE</label>
                                    <label data-target="#food-menu-grid" data-filter=".dinner">BUILD WARE</label>
                                    <label data-target="#food-menu-grid" data-filter=".drink">PAINTS</label>
                                    <label data-target="#food-menu-grid" data-filter=".party">ELECTRICAL</label>
                                    <label data-target="#food-menu-grid" data-filter=".coffee">PLUMBING</label>
                                </div>
                                <div id="food-menu-grid" className="isotop-grid row">

                                    {/* <div className="isotop-item res-food-menu-item breakfast drink col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/1.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div> */}

                                    {menuItems.map((item) =>
                                        // <div>{item.name}</div>
                                        <MenuItems key={item.id} data={item} />
                                    )}


                                    {/* <div className="isotop-item res-food-menu-item lunch drink party col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/2.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div>
                                    <div className="isotop-item res-food-menu-item dinner coffee col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/3.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div>
                                    <div className="isotop-item res-food-menu-item breakfast party lunch col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/4.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div>
                                    <div className="isotop-item res-food-menu-item dinner coffee col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/5.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div>
                                    <div className="isotop-item res-food-menu-item breakfast drink lunch col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/1.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div>
                                    <div className="isotop-item res-food-menu-item lunch party col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/2.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div>
                                    <div className="isotop-item res-food-menu-item dinner drink coffee col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/3.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div>
                                    <div className="isotop-item res-food-menu-item breakfast party col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/4.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div>
                                    <div className="isotop-item res-food-menu-item dinner coffee col-lg-6 col-12">
                                        <div className="image float-left"><img src="img/restaurant/food-menu/5.jpg" alt /></div>
                                        <div className="content fix">
                                            <h5>Fried Potatoes With Garlic</h5>
                                            <p>Proin accumsan est ac iaculis ullamcorper Integer.</p>
                                            <h4>$20</h4>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Menu
